/* @import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;800&family=Poppins:wght@400;700&family=Roboto:wght@400;700&display=swap'); */
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  /* font-family: 'Open Sans', sans-serif; */
  /* font-weight: 400; */
  font-family: "Inter", sans-serif;
  font-optical-sizing: auto;
  /* font-style: normal; */
  font-variation-settings: "slnt" 0;
}

:root {
  --scrollbarBG: #cfd8dc;
  --thumbBG: #777;
}

/* ScrollBar */
::-webkit-scrollbar {
  width: 7px; /* Adjust the width as needed */
}

::-webkit-scrollbar-thumb {
  background-color: var(--thumbBG);
  border-radius: 3px; /* Adjust the border-radius for a thicker thumb */
  border: 1px solid var(--scrollbarBG); /* Adjust the border width */
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

::-webkit-scrollbar-track {
  background: var(--scrollbarBG);
  border-radius: 5px;
}

a {
  /* color: black; */
  text-decoration: none;
  /* margin: 0 0.5rem; */
}

a:hover {
  font-weight: 600;
  color: #333;
}
